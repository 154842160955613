import { GameProviderAccount } from './schema';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { http } from './Connect';
import type { AxiosResponse } from 'axios';

export const getGameProviderAccount = (
  accountId: number
): Promise<GameProviderAccount> => {
  if (isSuperAdmin) {
    return http
      .get(`/api/v1/super-admin/game-provider/account/${accountId}`)
      .then(({ data }: AxiosResponse): GameProviderAccount => data);
  }

  return http
    .get(`/api/v1/game-provider/account`)
    .then(({ data }: AxiosResponse): GameProviderAccount => data);
};

export const updateGameProviderAccount = (
  accountId: number,
  data: Partial<GameProviderAccount>
): Promise<GameProviderAccount> => {
  if (isSuperAdmin) {
    return http
      .patch(`/api/v1/super-admin/game-provider/account/${accountId}`, data)
      .then(({ data }: AxiosResponse): GameProviderAccount => data);
  }

  return http
    .patch(`/api/v1/game-provider/account`, data)
    .then(({ data }: AxiosResponse): GameProviderAccount => data);
};

export const deleteGameProviderAccount = (accountId: string): Promise<void> => {
  if (isSuperAdmin) {
    return http.delete(
      `/api/v1/super-admin/game-provider/account/${accountId}`
    );
  }

  throw new Error('Only super admin can delete game provider account.');
};
