






import { mapState } from 'vuex';
import GameProviderSettingsForm from '@/components/game-provider/GameProviderSettingsForm.vue';

export default {
  name: 'GameProviderSettingsView',
  components: {
    GameProviderSettingsForm
  },
  computed: {
    ...mapState('Auth', ['accountId'])
  }
};
