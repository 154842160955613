























































import { mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { GameProviderAccount } from '@/api/schema';
import {
  getGameProviderAccount,
  updateGameProviderAccount,
  deleteGameProviderAccount
} from '@/api/GameProvider';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

export default {
  name: 'GameProviderSettingsForm',
  components: {
    ConfirmationDialog
  },
  props: {
    accountId: {
      type: Number,
      required: true
    }
  },
  data(): Record<string, unknown> {
    return {
      isGameProviderAccountLoading: true,
      gameProviderAccount: {},
      formData: {},
      isFormValid: false,
      isDeleteDialogOpened: false,
      isDeleteDialogProcessing: false,
      deleteDialogData: null
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    rules(): any {
      return {
        gameProviderName: [vr.required, (v) => vr.minLength(v, 3)],
        email: [vr.required, vr.isEmail],
        companyName: [vr.required, (v) => vr.minLength(v, 3)],
        companyAddress: [vr.required, (v) => vr.minLength(v, 3)]
      };
    }
  },
  watch: {
    gameProviderAccount: {
      handler(gameProviderAccount: GameProviderAccount): void {
        this.formData = {
          gameProviderName: gameProviderAccount.gameProviderName,
          email: gameProviderAccount.email,
          companyName: gameProviderAccount.companyName ?? '',
          companyAddress: gameProviderAccount.companyAddress ?? ''
        };

        this.$refs.form?.resetValidation();
      },
      deep: true
    },
    accountId: {
      handler(): void {
        this.loadGameProviderAccount();
      },
      immediate: true
    }
  },
  methods: {
    async loadGameProviderAccount(): Promise<void> {
      try {
        this.isGameProviderAccountLoading = true;
        this.gameProviderAccount = await getGameProviderAccount(
          this.accountId
        );
        this.isGameProviderAccountLoading = false;
      } catch (error) {
        errorToastMessage(error);
      }
    },
    openDeleteDialog(account: GameProviderAccount): void {
      this.isDeleteDialogOpened = true;
      this.deleteDialogData = {
        header: `Are you sure to delete ${account.gameProviderName} game provider account`,
        okText: 'Delete'
      };
    },
    closeDeleteDialog(): void {
      this.isDeleteDialogOpened = false;
      this.deleteDialogData = null;
    },
    async clickSaveAccountButtonHandler(): Promise<void> {
      const form = this.$refs.form;
      form.validate();
      await this.$nextTick();
      if (!this.isFormValid) {
        return;
      }
      try {
        this.gameProviderAccount = await updateGameProviderAccount(
          this.accountId,
          {
            ...this.gameProviderAccount,
            ...this.formData
          } as GameProviderAccount
        );
        this.$toast.success('Account was successfully updated!');
      } catch (error) {
        errorToastMessage(error);
      }
    },
    async clickDeleteAccountButtonHandler(): Promise<void> {
      await this.openDeleteDialog(this.gameProviderAccount);
    },
    closeDeleteDialogHandler(): void {
      this.closeDeleteDialog();
    },
    async submitDeleteDialogHandler(): Promise<void> {
      try {
        this.isDeleteDialogProcessing = true;
        await deleteGameProviderAccount(this.gameProviderAccount.id);
        this.$toast.success(`Account was successfully deleted!`);
        this.closeDeleteDialog();
        this.$emit('deleted');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isDeleteDialogProcessing = false;
      }
    }
  }
};
